<template>
  <div>
    <WidgetsTeacher />
    <CRow>
      <CCol lg="6">
        <CardScoringFormTeacher />
      </CCol>

    </CRow>
  </div>
</template>

<script>
import CardScoringFormTeacher from "./components/CardScoringFormTeacher";
import CardUploadScore from "./components/CardUploadScore";
import SltAmphur from "./components/SltAmphur";
import WidgetsTeacher from "./widgets/WidgetsTeacher";
import TblAttendanceByTeacher from "./components/TblAttendanceByTeacher";
import TblEnrollmentByTeacher from "./components/TblMyEnrollmentByTeacher";
import TblAttendanceSummary from "./components/TblAttendanceSummary";

export default {
  name: "Dashboard",
  components: {
    CardScoringFormTeacher,
    CardUploadScore,
    SltAmphur,
    WidgetsTeacher,
    TblAttendanceByTeacher,
    TblEnrollmentByTeacher,
    TblAttendanceSummary,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
    };
  },
  methods: {},
  mounted() {
    //console.log(this.userData);
  },
};
</script>
